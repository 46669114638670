<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item link to="/">
          <v-list-item-content>
            <v-list-item-title>
              Inicio
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="1 === 2" link to="/consultoria-software">
          <v-list-item-content>
            <v-list-item-title>
              Consultoría de Software
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/comercio-electronico">
          <v-list-item-content>
            <v-list-item-title>
              Comercio Electrónico
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link disabled to="/pos">
          <v-list-item-content>
            <v-list-item-title>
              Sistema POS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link disabled to="/agencia-inmobiliaria">
          <v-list-item-content>
            <v-list-item-title>
              Portal para agencia inmobiliaria
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="https://www.comiapp.com/">
          <v-list-item-content>
            <v-list-item-title>
              ComiApp
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link href="tel:0573045883845">
          <v-list-item-content>
            <v-list-item-title>
              Llámanos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar height="80" app clipped-left color="blue">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="header">
        <router-link to="/">
          <img alt="Vue logo" src="@/assets/logo-white.png" />
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app color="blue" light>
      <div class="footer">
        Copyright &copy; {{ new Date().getFullYear() }}
        <a href="https://www.fenixsoftware.co" class="link-footer"
          >www.fenixsoftware.co</a
        >
        | Ibagué - Colombia | All Rights Reserved
      </div>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
.header {
  text-align: center;
  width: 100%;
}
.footer {
  text-align: center;
  color: white;
  width: 100%;
}
.v-main {
  padding-top: 59px !important;
}
.col-banner {
  padding: 0px !important;
}
.link-footer {
  color: white !important;
  font-weight: bold;
}
.center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
</style>

<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: false
  }),
  created() {
    // this.$vuetify.theme.dark = true;
  }
};
</script>
